export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"The cold water cleaner for ALL of today's oral appliances!"},{"hid":"keywords","name":"keywords","content":"OrthoFresh, Braebon Medical Corporation, Oral Appliance Cleaner, Cold Water Cleaner, Orthodontic Appliance Cleaner"},{"hid":"robots","name":"robots","content":"index, follow"},{"property":"og:site_name","content":"OrthoFresh - A Product of Braebon Medical Corporation"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:url","property":"og:url","content":"https://orthofresh.com"},{"hid":"og:image:secure_url","property":"og:image:secure_url","content":"https://orthofresh.com/public/img/banner.webp"},{"hid":"og:title","property":"og:title","content":"OrthoFresh - A Product of Braebon Medical Corporation"},{"hid":"og:description","property":"og:description","content":"The cold water cleaner for ALL of today's oral appliances!"},{"hid":"og:image","property":"og:image","content":"https://orthofresh.com/public/img/banner.webp"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"title":"OrthoFresh - A Product of Braebon Medical Corporation","titleTemplate":"%s | OrthoFresh","charset":"utf-8","htmlAttrs":{"lang":"en"},"viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000